@use '../styles/theme';

:local(.icon-button) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s ease;

  svg {
    color: var(--text1-color);
    transition: color 0.2s ease, transform 0.2s ease;
  }

  & > * {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:hover {
    color: var(--primary-color-hover);
    background-color: rgba(var(--primary-color-rgb), 0.1);

    svg {
      color: var(--primary-color-hover);
      transform: scale(1.1);
    }
  }

  &:active {
    color: var(--primary-color-pressed);
    background-color: rgba(var(--primary-color-rgb), 0.2);

    svg {
      color: var(--primary-color-pressed);
      transform: scale(0.95);
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: var(--disabled-icon-color);
    cursor: not-allowed;
    opacity: 0.6;

    svg {
      color: var(--disabled-icon-color);
    }

    &:hover {
      background-color: transparent;
      svg {
        transform: none;
      }
    }
  }

  // For file upload buttons
  input {
    opacity: 0;
    width: 0;
    overflow: hidden;
    position: absolute;

    &:focus {
      outline: none;
    }

    &::-webkit-file-upload-button, &::file-selector-button {
      visibility: hidden;
    }
  }

  &:local(.compact-sm) {
    @media(max-width: theme.$breakpoint-lg - 1), (max-height: theme.$breakpoint-vr - 1) {
      flex-direction: column;
      justify-content: center;
      padding: 6px;

      & > * {
        margin-right: 0;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  :global(.keyboard-user) &:focus-visible {
    box-shadow: 0 0 0 3px var(--outline-color);
  }
}

:local(.lg) {
  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    font-size: var(--font-size-sm);
    padding: 10px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
}