@use "../styles/theme.scss";

:local(.close-button) {
  padding: 8px;
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;

  svg {
    width: 16px;
    height: 16px;
    color: var(--text2-color);
    transition: color 0.2s ease;
  }

  &:hover {
    background-color: rgba(var(--text2-color-rgb), 0.1);

    svg {
      color: var(--text1-color);
    }
  }

  &:active {
    background-color: rgba(var(--text2-color-rgb), 0.2);
  }
}

:local(.lg) {
  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

