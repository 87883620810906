@use "../styles/theme.scss";

$checkbox-size: 20px;

:local(.checkbox-input) {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: var(--font-size-md);
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

:local(.checkmark) {
  position: absolute;
  top: 0;
  left: 0;
  height: $checkbox-size;
  width: $checkbox-size;
  border-radius: 4px;
  border: 2px solid var(--border1-color);
  background-color: var(--background1-color);
  transition: all 0.2s ease;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid var(--text5-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

:local(.checkbox-input):hover input ~ :local(.checkmark) {
  border-color: var(--primary-color);
}

:local(.checkbox-input) input:checked ~ :local(.checkmark) {
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  &:after {
    display: block;
  }
}

:local(.disabled) {
  opacity: 0.5;
  cursor: not-allowed;
}

:local(.label-container) {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  color: var(--text2-color);
}

:local(.label) {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

:local(.description) {
  margin-top: 4px;
  font-size: var(--font-size-xs);
  color: var(--text3-color);
}

