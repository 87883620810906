@use "../styles/theme.scss";

:local(.button) {
  height: 48px;
  min-height: 48px;
  width: min-content;
  min-width: 156px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
  border-radius: 12px;
  border: none;
  transition: all 0.3s ease;
  white-space: nowrap;
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }

  &:hover::before {
    transform: translateX(100%);
  }

  svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
}

:local(.basic) {
  color: var(--text2-color);
  background-color: var(--basic-color);
  box-shadow: inset 0 0 0 2px var(--basic-border-color);

  svg {
    color: var(--text2-color);
  }

  &:hover {
    color: var(--text2-color-hover);
    background-color: var(--basic-color-hover);
    box-shadow: inset 0 0 0 2px var(--basic-border-color), 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    color: var(--text2-color-pressed);
    background-color: var(--basic-color-pressed);
    box-shadow: inset 0 0 0 2px var(--basic-border-color), 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

:local(.transparent) {
  color: var(--text2-color);
  background-color: var(--background1-color);
  box-shadow: inset 0 0 0 2px var(--background1-color);

  &:hover {
    background-color: var(--basic-color-hover);
  }

  &:active {
    background-color: var(--basic-color-pressed);
  }
}

:local(.primary) {
  color: var(--text5-color);
  background: linear-gradient(45deg, var(--primary-color), var(--accent4-color));
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

  svg {
    color: var(--text5-color);
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
}

:local(.accept),
:local(.cancel),
:local(.accent1),
:local(.accent2),
:local(.accent3),
:local(.accent4),
:local(.accent5),
:local(.accent6) {
  color: var(--text5-color);
  background: linear-gradient(45deg, var(--accent1-color), var(--accent2-color));
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

  svg {
    color: var(--text5-color);
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
}

:local(.button):disabled {
  cursor: not-allowed;
  color: var(--disabled-text-color);
  background: var(--disabled-bg-color);
  box-shadow: none;
  opacity: 0.7;

  &:hover {
    transform: none;
    box-shadow: none;
  }
}

:global(.keyboard-user) :local(.button):focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--primary-color);
}

:local(.sm) {
  height: 32px;
  min-height: 32px;
  min-width: 100px;
  padding: 0 16px;
  font-size: var(--font-size-xs);
  border-radius: 8px;
}

:local(.lg),
:local(.xl) {
  border-radius: 32px;
  padding: 0 48px;
  font-size: var(--font-size-md);
}

:local(.lg) {
  height: 48px;
}

:local(.xl) {
  height: 64px;
}

:local(.thin) {
  min-height: 41px;
  height: 41px;
  &:hover {
    color: var(--text5-color-hover);
  }
}

:local(.thick) {
  height: 53px;
}

:local(.signin) {
  background-color: transparent;
  color: var(--primary-color);
  box-shadow: inset 0 0 0 2px var(--primary-color);
  border-radius: 13px;

  &:hover {
    background-color: var(--primary-color);
    color: var(--text5-color);
  }
}

:local(.landing) {
  background-color: var(--primary-color);
  color: var(--text5-color);
  border-radius: 13px;

  &:hover {
    background-color: var(--primary-color-hover);
  }

  &:active {
    background-color: var(--primary-color-pressed);
  }
}

:local(.text) {
  border: none;
  background-color: var(--transparent);
  color: var(--primary-color);
  padding: 0px;
  box-sizing: border-box;

  &:hover {
    text-decoration: underline;
  }
}

