@use "../styles/theme";

:local(.input-field) {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  font-family: var(--font-family-default);
}

:local(.label) {
  margin-bottom: 8px;
  color: var(--text3-color);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  transition: color 0.2s ease;
}

:local(.input) {
  padding: 10px 12px;
  border: 2px solid var(--border1-color);
  border-radius: 8px;
  font-size: var(--font-size-md);
  color: var(--text1-color);
  background-color: var(--background1-color);
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.2);
  }

  &:hover:not(:focus) {
    border-color: var(--border2-color);
  }
}

:local(.fullWidth) {
  max-width: 100%;
}

:local(.error) {
  margin-top: 8px;
  color: var(--error-color);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

:local(.info) {
  margin-top: 8px;
  color: var(--text2-color);
  font-size: var(--font-size-xs);
}

:local(.input-field):focus-within :local(.label) {
  color: var(--primary-color);
}

